import { useTheme, useMediaQuery } from '@mui/material';
import AppbarDesktop from './AppbarDesktop';
import AppbarMobile from './AppbarMobile';
import { TMenu } from '../../lib/types';

const TITLE: TMenu = { to: '/', label: 'BottomsUp' };
const MENU: TMenu[] = [
    { to: '/', label: 'Home' },
    { to: '/send', label: 'Send' },
    { to: '/receive', label: 'Receive' },
];
export default function Appbar(): React.ReactElement {
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {smallDevice && <AppbarMobile title={TITLE} menu={MENU} />}
            {!smallDevice && <AppbarDesktop title={TITLE} menu={MENU} />}
        </>
    );
}
