import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';

function HomePage(): React.ReactElement {
    const navigate = useNavigate();

    return (
        <Container sx={{ marginTop: 4 }}>
            <Typography mt={5} sx={{ textAlign: 'center' }} variant="h3">
                !!! Welcome to Bottoms-Up !!!
            </Typography>
            <Box
                mt={5}
                display={'flex'}
                justifyContent={'center'}
                sx={{ fontSize: 20 }}
            >
                Bottoms-Up is simple app to encrypt and share a &nbsp;
                <span style={{ color: 'red' }}>secret</span> &nbsp; with the
                assurance that it can be read &nbsp;
                <span style={{ color: 'blue' }}>only once</span>.
            </Box>
            <Box mt={3} display={'flex'} justifyContent={'space-evenly'}>
                <Button
                    variant="contained"
                    color="success"
                    onClick={() => navigate('/send')}
                >
                    Encrypt a Secret
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => navigate('/receive')}
                >
                    Decrypt a Secret
                </Button>
            </Box>

            <Box mt={3}>
                <Typography variant="body1" align="justify">
                    With Bottoms-Up, your secret is encrypted in browser using
                    AES-256 CBC cipher. Key used to encrypt your secret is
                    generated in your browser and is never send over network.
                    Once encryption is completed, this encrypted payload is send
                    to server along with an secret identifier. A link is
                    generated in your browser which contains key used in
                    encryption and the secret identifier. You can share this
                    link with someone using channel like eMail or SMS etc. If
                    user is able to retreive secret successfully it means secret
                    is safe and not tempered, but in case receiver not able to
                    retreive secret, it means it is compromised and better to
                    use new one.
                </Typography>
            </Box>
        </Container>
    );
}

export default HomePage;
