import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LockOpenOutlined } from '@mui/icons-material';
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import queryString from 'query-string';
import { getSecret } from '../../lib/bottomsUpService';
import { decryptMessage, importAes256CbcKey } from '../../lib/crypto-lib';

enum UI {
    Secret,
    Link,
    Error,
    Consumed,
}

function Secret({ secret }: { secret: string }) {
    const [buttonText, setButtonText] = useState('Copy Your Secret');
    return (
        <>
            <Box width="100%" maxWidth={'md'} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            value={secret}
                            multiline
                            fullWidth
                            minRows={5}
                            maxRows={5}
                            label="Secret"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Button
                variant="contained"
                onClick={() => {
                    navigator.clipboard.writeText(secret);
                    setButtonText('!!! Copied !!!');
                    setInterval(() => setButtonText('Copy Your Secret'), 4000);
                }}
                sx={{ mt: 5, width: 300 }}
            >
                {buttonText}
            </Button>
        </>
    );
}

function Link({
    id,
    secretKey,
    setSecret,
    setUiState,
}: {
    id: string;
    secretKey: string;
    setSecret: (secret: string) => void;
    setUiState: (state: UI) => void;
}) {
    const [idInError, setIdInError] = useState(false);
    const [secretKeyInError, setSecretKeyInError] = useState(false);
    const [isDecrypting, setIsDecrypting] = useState(false);

    const handleSubmit = async () => {
        let validationError = false;

        if (id === '') {
            validationError = true;
            setIdInError(true);
        }

        if (secretKey === '') {
            validationError = true;
            setSecretKeyInError(true);
        }

        if (validationError) {
            return;
        }
        setIsDecrypting(true);

        const key = await importAes256CbcKey(secretKey);

        const result = await getSecret(id);

        if (typeof result === 'number') {
            if (result === 404) {
                setUiState(UI.Consumed);
            } else {
                setUiState(UI.Error);
            }
            return;
        }

        const secretText = await decryptMessage(result.secret, key, result.iv);
        setSecret(secretText);
        setUiState(UI.Secret);
        setIsDecrypting(false);
    };

    return (
        <>
            <Box width="100%" maxWidth={'md'} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            error={idInError}
                            helperText={idInError ? 'SecretId is required' : ''}
                            disabled
                            value={id}
                            fullWidth
                            label="SecretId"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={secretKeyInError}
                            helperText={
                                secretKeyInError ? 'SecretKey is required' : ''
                            }
                            disabled
                            value={secretKey}
                            fullWidth
                            label="SecretKey"
                        />
                    </Grid>
                </Grid>
            </Box>
            {!isDecrypting && (
                <Button
                    variant="contained"
                    sx={{ mt: 5, width: 300 }}
                    onClick={handleSubmit}
                >
                    Decrypt Your Secret
                </Button>
            )}
            {isDecrypting && (
                <Button
                    variant="contained"
                    sx={{ mt: 5, width: 300 }}
                    disabled={true}
                >
                    <CircularProgress
                        size={18}
                        color="info"
                        sx={{ marginRight: 2 }}
                    />
                    Decrypting...
                </Button>
            )}
        </>
    );
}

function Decrypt() {
    const location = useLocation();

    const [uiState, setUiState] = useState(UI.Link);

    const [id, setId] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [secret, setSecret] = useState('');

    useEffect(() => {
        const hashParam = queryString.parse(window.location.hash) as {
            id: string;
            key: string;
        };
        setId(hashParam.id ?? '');
        setSecretKey(hashParam.key ?? '');
    }, []);

    useEffect(() => {
        if (id) {
            history.replaceState(null, '', location.pathname);
        }
    }, [id]);

    return (
        <Container maxWidth="md">
            <Box
                component="form"
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOpenOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {UI.Link == uiState
                        ? 'Decrypt Your Secret'
                        : 'Copy Your Secret'}
                </Typography>

                {(UI.Consumed === uiState || UI.Error === uiState) && (
                    <Box maxWidth={'md'} sx={{ mt: 3 }}>
                        <Alert variant="filled" severity="error">
                            {UI.Consumed === uiState
                                ? 'Secret Expired or Consumed'
                                : 'Error Decrypting Secret'}
                        </Alert>
                    </Box>
                )}

                {UI.Secret === uiState && <Secret secret={secret} />}

                {UI.Secret !== uiState && (
                    <Link
                        id={id}
                        secretKey={secretKey}
                        setUiState={setUiState}
                        setSecret={setSecret}
                    />
                )}
            </Box>
        </Container>
    );
}

export default Decrypt;
