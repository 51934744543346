import { useState } from 'react';
import { LockOutlined } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { postSecret } from '../../lib/bottomsUpService';
import {
    encryptMessage,
    exportAes256CbcKey,
    getAes256CbcKey,
    uint8ArrayToBase64,
} from '../../lib/crypto-lib';

enum UI {
    Secret,
    Link,
    Error,
}

function Secret({
    setSecretLink,
    setUiState,
}: {
    setSecretLink: (secretLink: string) => void;
    setUiState: (ui: UI) => void;
}) {
    const [secret, setSecret] = useState('');
    const [secretMaxLife, setSecretMaxLife] = useState<string | number>(1);
    const [secretInError, setSecretInError] = useState(false);
    const [inBackendError, setInBackendError] = useState(false);
    const [isEncrypting, setIsEncrypting] = useState(false);

    const handleSubmit = async () => {
        if (secret === '') {
            setSecretInError(true);
            return;
        } else {
            setSecretInError(false);
        }

        setIsEncrypting(true);

        const iv = window.crypto.getRandomValues(new Uint8Array(16));

        const ivBase64 = uint8ArrayToBase64(iv);

        const key = await getAes256CbcKey();

        const encryptedMessage = await encryptMessage(secret, key, iv);

        const payload = {
            iv: ivBase64,
            secret: encryptedMessage,
            secretLife: secretMaxLife,
        };

        const resp = await postSecret(payload);

        if (typeof resp == 'number') {
            setInBackendError(true);
            console.log('Backend Error', inBackendError);
        } else {
            setInBackendError(false);
            const id = resp.id;
            const base64Key = await exportAes256CbcKey(key);
            const link = `${location.protocol}//${location.host}/receive#id=${id}&key=${base64Key}`;
            setIsEncrypting(false);
            setSecretLink(link);
            setUiState(UI.Link);
        }
    };

    return (
        <>
            <Box width="100%" maxWidth={'md'} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            error={secretInError}
                            onFocus={() => setSecretInError(false)}
                            helperText={secretInError ? 'Incorrect Entry' : ''}
                            value={secret}
                            onChange={(e) => setSecret(e.target.value)}
                            multiline
                            fullWidth
                            minRows={5}
                            maxRows={5}
                            label="Secret"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="secretMaxLifeLabelId">
                                Auto Delete Secret After
                            </InputLabel>

                            <Select
                                labelId="secretMaxLifeLabelId"
                                label="Auto Delete Secret After"
                                fullWidth
                                onChange={(e) =>
                                    setSecretMaxLife(e.target.value)
                                }
                                value={secretMaxLife}
                            >
                                <MenuItem value={1}>One Hour</MenuItem>
                                <MenuItem value={2}>Two Hours</MenuItem>
                                <MenuItem value={3}>Three Hours</MenuItem>
                                <MenuItem value={4}>Four Hours</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            {isEncrypting && (
                <Button
                    disabled={true}
                    variant="contained"
                    sx={{ mt: 5, width: 300 }}
                >
                    <CircularProgress
                        size={18}
                        color="info"
                        sx={{ marginRight: 2 }}
                    />
                    Encrypting...
                </Button>
            )}
            {!isEncrypting && (
                <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ mt: 5, width: 300 }}
                >
                    Encrypt Your Secret
                </Button>
            )}
        </>
    );
}

function Link({ secretLink }: { secretLink: string }) {
    const [buttonText, setButtonText] = useState('Copy Your Link');

    return (
        <>
            <Box width="100%" maxWidth={'md'} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            value={secretLink}
                            fullWidth
                            label="Link"
                        />
                    </Grid>
                </Grid>
            </Box>
            <Button
                onClick={() => {
                    navigator.clipboard.writeText(secretLink);
                    setButtonText('!!! Copied !!!');
                    setInterval(() => setButtonText('Copy Your Link'), 4000);
                }}
                type="submit"
                variant="contained"
                sx={{ mt: 5, width: 300 }}
            >
                {buttonText}
            </Button>
        </>
    );
}

export default function Encrypt() {
    const [uiState, setUiState] = useState(UI.Secret);
    const [secretLink, setSecretLink] = useState('');

    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {UI.Secret == uiState
                        ? 'Encrypt Your Secret'
                        : 'Copy Your Link'}
                </Typography>
                {UI.Secret === uiState && (
                    <Secret
                        setUiState={setUiState}
                        setSecretLink={setSecretLink}
                    />
                )}
                {UI.Link === uiState && <Link secretLink={secretLink} />}
            </Box>
        </Container>
    );
}
