import primary from '@mui/material/colors/indigo';
import secondary from '@mui/material/colors/pink';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppRouter from './AppRouter';

import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
    palette: {
        primary: primary,
        secondary: secondary,
    },
});
export default function App(): React.ReactElement {
    return (
        <ThemeProvider theme={theme}>
            <AppRouter />
        </ThemeProvider>
    );
}
