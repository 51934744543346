import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Appbar from './components/AppBar/Appbar';
import Decrypt from './components/BottomsUp/Decrypt';
import Encrypt from './components/BottomsUp/Encrypt';
import HomePage from './components/BottomsUp/HomePage';
import Error from './components/Error/Error';

function AppRouter(): React.ReactElement {
    return (
        <BrowserRouter>
            <Appbar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/send" element={<Encrypt />} />
                <Route path="/receive" element={<Decrypt />} />
                <Route
                    path="/*"
                    element={
                        <Error
                            errorcode="404"
                            title="Page Not Found"
                            message="We don't have what you looking for."
                        />
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
