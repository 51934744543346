import { fetchWithTimeout } from './util';

export async function postSecret(
    payload: object
): Promise<{ id: string } | number> {
    const apiPath = `/api/secrets`;
    try {
        const response = await fetchWithTimeout(apiPath, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            const apiResponse: { id: string } = await response.json();
            return apiResponse;
        } else {
            return response.status;
        }
    } catch {
        return 999;
    }
}

export async function getSecret(
    id: string
): Promise<{ iv: string; secret: string } | number> {
    const apiPath = `/api/secrets?id=${id}`;
    try {
        const response = await fetchWithTimeout(apiPath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const apiResponse: { iv: string; secret: string } =
                await response.json();
            return apiResponse;
        } else {
            return response.status;
        }
    } catch {
        return 999;
    }
}
